import { Info } from "@mui/icons-material"
import { CustomRALPrice } from "./globals"

export const mergeHardwareKitsIntoPricing = (product, hardware_kits) => {
  if(product.hardware_kits && hardware_kits && !product.hardwareKitsMerged){
    product.hardware_kits.forEach((item, i) => {
        let findKit = hardware_kits.find((kit)=>{
          return kit.node.childMarkdownRemark.fileAbsolutePath.includes(item.hardware_kit)
        })
        if(findKit){
          findKit = findKit.node.childMarkdownRemark.frontmatter
          let description = findKit.short_description
          if(item.description){
            description = item.description
          }
          product.pricing_options.push({
            description: description,
            model: findKit.model,
            modified_standard: false,
            base_configuration: false,
            net_price: findKit.net_price,
            smart_codes_selected: item.smart_code_restriction,
            default_quantity: item.default_quantity,
            product_class: findKit.product_class,
            short_description: findKit.short_description,
            contents: findKit.contents,
            weight: null,
            hardwareKit: true
          })
        }
    })
  }
  product.hardwareKitsMerged = true
  return product
}

export const formatPricing = (metaData, pricingOptions, productQuantity) => {
  let prices = getPricing(metaData, pricingOptions)
  let invoiceTotal = 0.0
  let freightSubTotal = 0.0
  let weightTotal = 0.0
  let currency = 'USD'
  let invoiceRows = []
  for(var i = 0; i < prices.length; i++){
    let option = {...prices[i]}
    let quantity = 1
    if(option.default_quantity){
      quantity = parseInt(option.default_quantity)
    }
    quantity = quantity * productQuantity
    invoiceTotal += (option.net_price[0].value * quantity)
    freightSubTotal += (option.net_price[0].value * quantity)
    
    weightTotal += parseFloat(option.weight ? parseFloat(option.weight) : 0)

    option.quantity = quantity
    option.pricePrint = 'please call for pricing'
    option.priceValue = null
    if(option.net_price[0].value){
      if(option.net_price[0].value > 0){
        option.pricePrint = new Intl.NumberFormat('en-US', { style: 'currency', currency: option.net_price[0].currency }).format(option.net_price[0].value)+' '+option.net_price[0].currency
      }
      option.priceValue = option.net_price[0].value
    }
    if(option.product_class){
      option.productClass = option.product_class
    }
    if(option.short_description){
      option.shortDescription = option.short_description
    }
    option.baseConfiguration = false
    if(option.base_configuration){
      option.baseConfiguration = option.base_configuration
    }
    option.optionalAddOn = false
    if(option['optional_add-on']){
      option.optionalAddOn = option['optional_add-on']
    }
    delete option.short_description
    delete option.product_class
    delete option.net_price
    delete option.smart_codes_selected
    delete option.default_quantity
    delete option.modified_standard
    if(option.weight){
      option.weightPrint = parseFloat(option.weight).toFixed(2) + ' lbs.'
    }
    invoiceRows.push(option)
  }
  //check for base configuration
  var baseConfigurationCount = 0
  for(i = 0; i < invoiceRows.length; i++){
    if(invoiceRows[i].baseConfiguration){
      baseConfigurationCount++
    }
  }
  //should send an alert if base configuration is invalid
  if(baseConfigurationCount < 1){
    if(invoiceRows[0]){
      invoiceRows[0].baseConfiguration = 1
    }
  }
  let subTotalPrint = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(invoiceTotal)
  let weightTotalPrint = parseFloat(weightTotal).toFixed(2) + ' lbs.'
  if(parseFloat(weightTotal) === 0.0){
    weightTotalPrint = 'Weight Not Available'
  }
  return {
    lineItems: invoiceRows,
    subTotal: invoiceTotal,
    subTotalPrint: subTotalPrint,
    freightSubTotal: invoiceTotal,
    weightTotal: weightTotal,
    weightTotalPrint: weightTotalPrint
  }
}


export const getPricing = (metaData, priceOptions) => {
  let smartParts = metaData.smartCode
  smartParts = smartParts.filter(element=>element!=='')
  let prices = []
  if(priceOptions){
    prices = priceOptions.filter((priceOption)=>{
      let pricematched = false
      if(priceOption?.smart_codes_selected?.length === 0){
        return false
      }else{
        if(priceOption.smart_codes_selected){
          let matches = priceOption.smart_codes_selected.map((item, i) => {
            if(!item.position){
              item.position = "0"
            }
            let positions = []
            if(item.position){
              positions = item.position.split(',')
            }
            positions = positions.map(position=>position.trim())
            let smartcodes = []
            if(item.smart_code){
              smartcodes = item.smart_code.split(',')
            }
            smartcodes = smartcodes.map(code=>code.trim())
            let found = false
            let wildcards = smartcodes.filter(code=>code.includes('*'));
            positions.forEach((position, i) => {
              if(smartcodes.includes(smartParts[position])){
                found = true
              }
              wildcards.forEach((wildcard, j)=>{
                if(smartParts[position].includes(wildcard.replace('*',''))){
                  found = true
                }
              })
            });
            
            //return false if equals PC/CUS
            if(item.smart_code.trim() === 'PC/CUS'){
              found = false
            }
            return found
          })
          let matched = true
          matches.forEach((match, i) => {
            if(!match){
              matched = false
            }
          })
          pricematched = matched
        }
        return pricematched
      }
    })
  }
  //check smart code multipliers
  prices.forEach((price, i) => {
    if(price.smart_code_multiplier){
      let pos = parseInt(price.smart_code_multiplier)
      let default_quantity = smartParts[pos]
      default_quantity = default_quantity.replaceAll(/[^0-9.]/ig)
      if(!parseInt(default_quantity)){
        default_quantity = 1
      }
      price.default_quantity = default_quantity
    }
  })
  //check for custom powdercoats
  prices.forEach((price, i) => {
    price.smart_codes_selected.forEach((item, j) => {
      if(item.smart_code.trim() === 'PC/CUS' && metaData){
        price.net_price[0].value = 0
        let customText = ''
        let label = ''
        let pos = parseInt(item?.position)
        if(metaData && pos){
          if(metaData?.smartDescription){
            let smartDescription = metaData.smartDescription.find(item=>{
              return item.smartCodePosition === pos
            })
            customText = smartDescription.customText
            label = smartDescription.label
          }
        }
        price.description = 'Custom RAL powdercoat color ' + customText + ' | ' + label
      }
    })
  })
  return prices
}

export const getPricingForProject = (project, products, hardwareKits) => {
  return new Promise((resolve, reject) => {
    try {
      let productsOut = []
      let downloads = {}
      let customRals = []
      //filter to relevant products
      var projectConfigIds = project.designs.map((design)=>{
        return design.configuratorId
      })
      var productsData = products.filter((edge)=>{
        return projectConfigIds.includes(edge.node.childMarkdownRemark.frontmatter.configurator_id)
      })
      for(var i = 0; i < productsData.length; i++){
        productsOut.push(mergeHardwareKitsIntoPricing(productsData[i].node.childMarkdownRemark.frontmatter, hardwareKits))
      }
      for(var j = 0; j < project.designs.length; j++){
        let configuratorId = project.designs[j].configuratorId
        let findProduct = productsOut.find((item)=>{
          return item.configurator_id === configuratorId
        })
        let metaData = {
          smartCode: project.designs[j].smartCode.split('-'),
          smartDescription: project.designs[j].smartDescription
        }
        let defaultQuantity = 1
        if(project.designs[j].quantity){
          defaultQuantity = project.designs[j].quantity
        }
        let tempPricing = formatPricing(metaData, findProduct?.pricing_options, defaultQuantity)
        //combine all options under base configuration
        let compiledPricing = tempPricing.lineItems.filter((lineItem)=>{
          return lineItem.baseConfiguration
        })
        tempPricing.lineItems.forEach((lineItem, i) => {
          if(!lineItem.baseConfiguration && !lineItem.hardwareKit && !lineItem.optionalAddOn){
            let quantityDesc = '';
            if(lineItem.quantity > compiledPricing[0].quantity){
              quantityDesc = ' ('+parseInt(lineItem.quantity/compiledPricing[0].quantity)+')'
            }
            compiledPricing[0].description += '\n\n'+lineItem.description+quantityDesc
            compiledPricing[0].weight = parseFloat(parseFloat(compiledPricing[0].weight) + (parseFloat(lineItem.weight) ? parseFloat(lineItem.weight) : 0))
            compiledPricing[0].weightPrint = compiledPricing[0].weight + ' lbs'
            compiledPricing[0].priceValue += ((lineItem.priceValue * lineItem.quantity) / compiledPricing[0].quantity)
            compiledPricing[0].pricePrint = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(compiledPricing[0].priceValue)+' USD'
          }
          //hardware kits still get their own separate line
          if(lineItem.hardwareKit || lineItem.optionalAddOn){
            compiledPricing.push(lineItem)
          }
        })
        tempPricing.lineItems = compiledPricing
        project.designs[j].pricing = tempPricing
        project.designs[j].downloads = findProduct?.external_documents
        if(findProduct?.external_documents){
          for(var k = 0; k < findProduct?.external_documents.length; k++){
            if(!downloads[findProduct.external_documents[k]['document_type']]){
              downloads[findProduct.external_documents[k]['document_type']] = {
                files: []
              }
            }
            downloads[findProduct.external_documents[k]['document_type']].files.push({file: findProduct.external_documents[k]['document_url']})
          }
        }
        //find custom ral colors
        if(project.designs[j].smartCode.includes('PC/CUS')){
          let smartCode = project.designs[j].smartCode.split('-')
          let designIndex = j
          smartCode.forEach((item, k) => {
            if(item === 'PC/CUS'){
              let descIndex = k
              let smartDescription = project.designs[designIndex].smartDescription.find(item=>{
                return item.smartCodePosition === descIndex
              })
              if(!smartDescription){
                smartDescription = project.designs[designIndex].smartDescription[descIndex]
              }
              let findRal = smartDescription?.customText.trim()
              let label = smartDescription?.label
              let findCustom = customRals.find(ral =>{
                return ral.value.trim() === findRal
              })
              if(!findCustom){
                customRals.push({
                  value: findRal,
                  priceValue: CustomRALPrice,
                  pricePrint: `$${CustomRALPrice} USD`,
                  quantity: 1,
                  model: 'RAL ' + findRal,
                  description: 'Custom RAL powdercoat color ' + findRal,
                  designs: [
                    {
                      id: project.designs[designIndex]._id,
                      label: label
                    }
                  ]
                })
              }else{
                if(findCustom.designs){
                  findCustom.designs.push({
                    id: project.designs[designIndex]._id,
                    label: label
                  })
                }else{
                  findCustom.designs = [{
                    id: project.designs[designIndex]._id,
                    label: label
                  }]
                }
              }
            }
          })
        }
      }
      customRals.forEach((item, i) => {
        let price = CustomRALPrice
        let pricePrint = `$${CustomRALPrice} USD`
        item.designs.forEach((design, j) => {
          if(j > 0){
            price = 0
            pricePrint = ''
          }
          let foundDesign = project.designs.find(designItem => {
            return designItem._id === design.id
          })
          if(foundDesign){
            foundDesign.pricing.lineItems.splice(1,0,{
              value: item.value,
              priceValue: price,
              pricePrint: pricePrint,
              quantity: 1,
              model: '',
              description: item.description + ' | ' + design.label
            })
            foundDesign.pricing.subTotal += price
            foundDesign.pricing.subTotalPrint = '$'+foundDesign.pricing.subTotal+' USD'
          }
        })

      })
      project.downloads = downloads
      resolve(project)
    } catch (err) {
      reject(err)
    }
  })
}
